import { ButtonMailto } from '../Button/Buttons';
import { InfoAlert, AlertType, InfoAlertProps } from './InfoAlert';

export const GenericErrorAlert = (props: InfoAlertProps) => (
  <>
    <InfoAlert type={AlertType.Error} {...props}>
      An unexpected error occurred. Try to reload the page. If the problem persists, please{' '}
      <ButtonMailto mailto="mailto:support@jobsboard.io" label="Contact Us" />
    </InfoAlert>
  </>
);
